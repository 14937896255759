<template>
  <div :class="$style.root">
    <div class="mb-4 clearfix">
      <VAlert v-if="needGoToSurvey && !isReviewer && !isSpectator" type="warning">
        <VRow align="center">
          <VCol class="grow" style="font-size: 18px;">
            Пройдите опрос и оцените функционал ПКО СМР</VCol>
          <VCol class="shrink">
            <VBtn :href="surveyLink" depressed outlined>Пройти опрос</VBtn>
          </VCol>
        </VRow>
      </VAlert>
      <h1 v-if="$route.meta.title">{{$route.meta.title}}</h1>
      <VBtn class="float-left" depressed @click="$router.push({ name: 'pko/PkoDashboardView'})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад
      </VBtn>
      <div class="float-right">
        <InstructionButton />
        <VBtn v-if="isReviewer || isSpectator || isGeneralReviewer" class="float-right ml-3" @click="$router.push({ name: 'pkosmr/RegistryListView'})" depressed color="primary">
          Реестр контрагентов
        </VBtn>
        <VBtn v-if="isReviewer || isSpectator || isGeneralReviewer" class="float-right ml-3" @click="$router.push({ name: 'pkosmr/FormRegistryListView'})" depressed color="primary">
          Реестр заявок
        </VBtn>
        <VBtn v-if="isReviewer || isSpectator || isGeneralReviewer" class="float-right ml-3" @click="$router.push({ name: 'pkosmr/SurveyListView'})" depressed color="primary">
          Результаты опроса
        </VBtn>
        <RoleSwitcher class="float-right" />
      </div>
    </div>
    <div class="mb-0" v-if="isContragent && !!registryCurrent && !!registryCurrent.length">
      <VRow>
        <VCol cols-md="12" md="6" v-for="({name, status, date}, index) in registryCurrent" :key="index">
          <VAlert v-bind="{...registryIconColorControl(status, date)}" class="py-2 my-0" style="height: 100%" dark dense>
            <b>{{ status.name }}</b>
            <span v-if="date"> до {{ date }} </span>
            для {{ name }}
          </VAlert>
        </VCol>
      </VRow>
    </div>
    <CounterpartyList
        :items="counterparties.items"
        :count="counterparties.count"
        :page="page"
        :size="size"
        :loading="loading"
    />
    <VFooter v-if="isContragent" app>
      <div class="py-3 grow">
        <CounterpartyDialog @success="onUpdateState" />
      </div>
    </VFooter>
  </div>
</template>

<script>
import {debounce, find, get, indexOf} from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import InstructionButton from '@/components/pkosmr/InstructionButton/InstructionButton';
import CounterpartyList from '@/components/pkosmr/CounterpartyList/CounterpartyList';
import CounterpartyDialog from '@/components/pkosmr/CounterpartyDialog/CounterpartyDialog';
import RoleSwitcher from '@/components/pkosmr/RoleSwitcher/RoleSwitcher';
import {BEFORE_EXPIRED_PKO_DAYS, STATUSES} from '@/store/pkosmr/enums';

export default {
  name: 'CounterpartyListView',
  components: {
    RoleSwitcher,
    CounterpartyList,
    CounterpartyDialog,
    InstructionButton,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object }
  },
  computed: {
    ...mapGetters({
      loading: 'pkosmr/pending',
      counterparties: 'pkosmr/counterparties',
      pkoList: 'pko/pkoList',
      hasRole: 'user/hasRole',
    }),
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    isGeneralReviewer() {
      return this.hasRole([ROLES.GENERAL_REVIEWER], 'pkosmr');
    },
    smrServiceInfo() {
      return find(get(this.pkoList, 'items', []), (item) => ~item.link.indexOf('pkosmr'));
    },
    needGoToSurvey() {
      return get(this.smrServiceInfo, 'additional.survey.active', false);
    },
    surveyLink() {
      return get(this.smrServiceInfo, 'additional.survey.link', '');
    },
    registryCurrent() {
      return get(this.smrServiceInfo, 'statuses', []);
    }
  },
  methods: {
    ...mapActions({
      fetchCounterpartyList: 'pkosmr/fetchCounterpartyList',
      fetchPkoList: 'pko/fetchPkoList',
    }),
    onUpdateState: debounce(function() {
      const { page, size, filter } = this;
      this.fetchCounterpartyList({ page, size, filter });
    }, 500),
    registryIconColorControl({code}, dateStatusEnd) {
      //«Зеленый» - анкета действующая
      // «Оранжеый» – срок действия анкеты истекает через 45 календарных дней
      // «Красный» - срок действия анкеты истек.

      if(STATUSES.NOT_PASSED.value === code) return {icon: 'mdi-alert-circle-outline', color: 'error'};
      if (STATUSES.PASSED.value === code && !dateStatusEnd.length) return {icon: 'mdi-check-circle-outline', color: 'success'};
      if (!dateStatusEnd.length) return {icon: 'mdi-progress-check', color: 'warning'};

      const dateEnd = Date.parse(dateStatusEnd.split('.').reverse().join('-'));
      const now = new Date();
      const diff = dateEnd - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));

      if(!!~indexOf([STATUSES.PASSED.value, STATUSES.ON_APPROVAL.value], code) && days <= BEFORE_EXPIRED_PKO_DAYS) return {icon: 'mdi-progress-check', color: 'warning'};
      if(!!~indexOf([STATUSES.PASSED.value, STATUSES.ON_APPROVAL.value], code) && days > BEFORE_EXPIRED_PKO_DAYS) return {icon: 'mdi-check-circle-outline', color: 'success'};
      return {icon: 'mdi-progress-check', color: 'warning'};
    }
  },
  watch: {
    page: function() {
      this.onUpdateState();
    },
    size: function() {
      this.onUpdateState();
    },
    filter: {
      deep: true,
      handler: function() {
        this.onUpdateState();
      }
    }
  },
  mounted() {
    this.onUpdateState();
    this.fetchPkoList();
  }
}
</script>

<style module lang="scss">
.root {}
</style>
