<template>
  <div>
    <VBtn color="primary" class="mr-2" @click="dialog = true"  depressed>Создать анкету</VBtn>
    <VDialog v-model="dialog" v-if="!!items.length" max-width="900" scrollable>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">Создать анкету</VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="dialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="py-0">
          <VRadioGroup v-model="code">
            <VRadio v-for="{text, value} in items" :label="text" :value="value" :key="value" />
          </VRadioGroup>
        </VCardText>
        <VCardActions class="pt-0">
          <VBtn color="primary" class="px-6" depressed @click="onCreate">
            Создать
          </VBtn>
          <VBtn color="secondary" text @click="dialog = false">
            Отмена
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import api from '@/store/pkosmr/api';
import { mapActions } from 'vuex';

export default {
  name: 'CounterpartyDialog',
  data() {
    return {
      code: null,
      dialog: false,
      items: [],
    }
  },
  methods: {
    ...mapActions({
      createCounterparty: 'pkosmr/createCounterparty',
    }),
    onUpdate() {
      return api.companiesForCreate().then((items) => this.items = items);
    },
    onCreate() {
      const code = this.code;
      this.createCounterparty({ code })
      .then(() => {
        this.dialog = false;
        this.$emit('success');
        this.onUpdate();
      });
    },
  },
  mounted() {
    this.onUpdate();
  }
}
</script>

<style module lang="scss">

</style>
