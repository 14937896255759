<template>
  <div :class="$style.root">
    <VDataTable
        :headers="[
          { text: 'ID', value: 'id', sortable: false },
          { text: 'ИНН', value: 'inn', sortable: false },
          { text: 'Организация', value: 'name', sortable: false },
          { text: 'Вид квалификации', value: 'qualification', sortable: false },
          { text: 'Статус', value: 'status.name', sortable: false },
        ]"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
          itemsPerPageText: '',
        }"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        :mobile-breakpoint="0"
        :item-class="getClassByRow"
        @click:row="({ id }) => $router.push({ name: 'pkosmr/CounterpartyDetailView', params: { counterparty: id }})"
        @update:page="page => $router.push({ query: { ...$route.query, page } })"
        @update:items-per-page="size => $router.push({ query: { ...$route.query, size } })"
    >
      <template v-slot:body.prepend>
        <tr v-if="isReviewer || isSpectator || isGeneralReviewer">
          <td v-for="key in ['id', 'inn', 'companyName', 'qualification']" :key="key">
            <VTextField
                dense
                clearable
                hide-details
                :value="$route.query[key]"
                @input="value => $router.push({ query: { ...$route.query, [key]: value } })"
            />
          </td>
          <td>
            <VSelect
                dense
                clearable
                hide-details
                :items="statuses"
                :value="$route.query['status']"
                @input="value => $router.push({ query: { ...$route.query, ['status']: value } })"
            />
          </td>
        </tr>
      </template>
      <template v-slot:item.control="{ item: { id, comments } }">
        <slot name="control" v-bind="{ id, comments }" />
      </template>
    </VDataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import { STATUSES } from '@/store/pkosmr/enums';
import { get, values, pick } from 'lodash-es';

export default {
  name: 'ContractList',
  props: {
    items: { type: Array },
    count: { type: Number },
    page: { type: Number },
    size: { type: Number },
    loading: { type: Boolean },
  },
  data: function() {
    return {
      options: {
        page: this.page,
        itemsPerPage: this.size
      },
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isGeneralReviewer() {
      return this.hasRole([ROLES.GENERAL_REVIEWER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    statuses() {
      if (this.isReviewer || this.isSpectator) return values(pick(STATUSES, ['NO','REWORK','YES','WAIT_FOR_DECISION']));
      return values(pick(STATUSES, ['ON_APPROVAL','PKO_NOT_PASSED','PKO_PASSED','PREPARE', 'ON_REWORK']));
    }
  },
  methods: {
    getClassByRow({ status }) {
      // 'orange lighten-4'
      if (this.isReviewer) {
        if (get(STATUSES, 'NO.value') === status.value) return '';
        if (get(STATUSES, 'REWORK.value') === status.value) return '';
        if (get(STATUSES, 'YES.value') === status.value) return '';
        if (get(STATUSES, 'WAIT_FOR_DECISION.value') === status.value) return 'error lighten-4';
      }
      if (this.isContragent) {
        if (get(STATUSES, 'ON_APPROVAL.value') === status.value) return '';
        if (get(STATUSES, 'PKO_NOT_PASSED.value') === status.value) return '';
        if (get(STATUSES, 'PKO_PASSED.value') === status.value) return '';
        if (get(STATUSES, 'PREPARE.value') === status.value) return '';
        if (get(STATUSES, 'ON_REWORK.value') === status.value) return 'error lighten-4';
      }
      return '';
    },
  }
}
</script>

<style module lang="scss">
.root {
  th:nth-child(1) {
    width: 1px !important;
  }
  th:nth-child(2) {
    width: 200px !important;
  }
  th:nth-child(3) {
    width: 300px !important;
  }
  th:nth-child(4) {
    width: 300px !important;
  }
  th:nth-child(5) {
    width: 200px !important;
  }
  th:last-child {
    width: 200px !important;
  }
}
</style>
